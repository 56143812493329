<template>
  <section>
    <div class="container mt-5">
      <Breadcrumb type="destination-flat" :stage="1" />
      <div class="row">
        <div class="col-lg-4 col-6 sports_box_four" v-for="(item, inx) in categories" :key="inx">
          <b-img-lazy v-bind="mainProps" :src="images[item.code]" alt="" />
          <div class="tag_line">
            <router-link :to="`/destination-list?packCategory=${item.code}`">
              <h3>{{ item.name[lang] }}</h3>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BImgLazy } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SeoLinkImage',
  components: {
    BImgLazy,
    Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
  },
  data() {
    return {
      images: {
        Flight_Only: 'assets/img/outlink/seo-flights.jpg',
        hotels: 'assets/img/outlink/seo-hotels.jpg',
        vacation_pack: 'assets/img/outlink/seo-lesiur-packages.jpg',
        Organize_tour_packages: 'assets/img/outlink/seo-organized-tours.jpg',
        'sport-packages': 'assets/img/outlink/seo-sport.jpg',
        events: 'assets/img/outlink/seo-show.jpg',
        'destination-guide': 'assets/img/outlink/seo-destinations.jpg',
        'dynamic-packages': 'assets/img/outlink/seo-dynamic-packages.jpg',
        'fly-and-drive': 'assets/img/outlink/seo-fly-and-drive.jpg',
        'villages-vacation': 'assets/img/outlink/seo-villages-packages.jpg',
        'low-cost-flights': 'assets/img/outlink/seo-low-cost.jpg',
        'hotels-in-israel': 'assets/img/outlink/seo-hotels-israel.jpg',
        'conventions-and-incentive': 'assets/img/outlink/seo-conventions.jpg',
        'tailor-made-tours': 'assets/img/outlink/seo-tailor-made.jpg',
        'organization-tours': 'assets/img/outlink/seo-organization-tours.jpg',
        'vip-tours': 'assets/img/outlink/seo-vip-tours.jpg',
      },
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        class: 'img-fluid',
      },
      groupCount: 4,
    };
  },
  computed: {
    ...mapGetters({
      landingDealData: 'GET_LANDING_DEAL_DATA',
      lang: 'GET_LANGUAGE',
      categories: 'GET_CATEGORIES',
    }),
  },
  mounted() {
    // console.log(this.categories);
  },
  methods: {
    getArrayStart(gIndex) {
      return (gIndex - 1) * this.groupCount;
    },
    getArrayEnd(gIndex) {
      return (gIndex - 1) * this.groupCount + this.groupCount;
    },
  },
};
</script>

<style scoped>
.sports_box_four {
  height: 130px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  margin-bottom: 25px;
}
.sports_box_four img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
}
.sports_box_four .tag_line {
  content: '';
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  background: rgb(255 255 255 / 67%);
  text-align: center;
  width: 60%;
}
.sports_box_four .tag_line h3 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0px;
}
.sports_box_four .tag_line a {
  color: #106cb1;
  font-size: 13px;
  font-weight: 500;
}
.sports_box_four:hover img{
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  transform: scale(1.2);
}
</style>
<style>

</style>
